<template>
    <span
        class="PromotionBarButton__wrapper"
        :class="{
            'PromotionBarButton__wrapper--with-custom-text-colour': textColour,
        }"
    >
        <div class="PromotionBarButton">
            {{ slice.primary.button_text }}
            <FontIcon
                icon="arrow"
                color="black"
                :style="{
                    '--font-icon-colour': slice.primary.button_text_colour,
                }"
                size="xx-small"
            />
        </div>
        <span class="PromotionBarButton__separator"></span>
    </span>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.PromotionBarButtonSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const textColour = ref(props.slice.primary.button_text_colour)
</script>

<style lang="scss" scoped>
.PromotionBarButton__wrapper {
    display: flex;
    align-items: center;

    &:last-child {
        .PromotionBarButton__separator {
            display: none;
        }
    }
}

.PromotionBarButton__wrapper--with-custom-text-colour {
    .PromotionBarButton {
        color: v-bind(textColour);
    }

    .PromotionBarButton__separator {
        background-color: v-bind(textColour);
    }
}

.PromotionBarButton {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    background-color: var(--white);
    font-weight: var(--font-weight-bold);
    color: var(--black);
    text-decoration: none;
}

.PromotionBarButton__separator {
    display: none;

    @include medium-up {
        display: block;
        background-color: var(--lime-green);
        width: 0.4rem;
        height: 0.4rem;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
}
</style>
